import { css } from 'styled-components';

import themeStyle from './theme';

export const mixins = Object.keys(themeStyle.breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media (min-width: ${themeStyle.breakpoints[label]}) {
        ${({ theme }) => css(theme, ...args)};
      }
    `;

    return accumulator;
  },
  {},
);
