import styled, { css } from 'styled-components';

import { mixins } from '../../../styles/responsive';

export const Container = styled.section`
  > div {
    margin-top: 4rem;
    width: 100%;
    height: 5rem;

    ${mixins.md(css`
      margin-top: 8rem;
      height: 10rem;
    `)};
  }
`;
