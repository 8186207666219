import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.button`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 7.75rem;
  border: 2px solid transparent;
  font-size: 2rem;
  padding: 2rem;
  transition: ease-in 0.2s all;

  &:hover {
    transform: scale(1.05);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.9;
      cursor: not-allowed;
    `}

  ${mixins.sm(css`
    font-size: 3rem;
    padding: 1.5rem 5rem;
  `)}
`;
