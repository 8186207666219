import React, { useCallback, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { Container } from './styles';

function Input({ label, type, onChange, ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleOnChange = useCallback(
    e => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <Container>
      <label htmlFor={label}>{label}</label>
      <div>
        <input
          {...props}
          onChange={handleOnChange}
          id={label}
          type={showPassword ? 'text' : type}
          placeholder={label}
        />

        {type === 'password' && (
          <button onClick={() => setShowPassword(!showPassword)} type="button">
            {showPassword ? <FiEyeOff /> : <FiEye />}
          </button>
        )}
      </div>
    </Container>
  );
}

export default Input;
