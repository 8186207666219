import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;

  > header {
    min-height: 9.625rem;
    display: flex;
    align-items: center;
    padding: 0 3rem;
    font-weight: 900;
    font-size: 2.5rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primary};
    border-bottom: 2px solid rgba(112, 124, 151, 0.15);
    backdrop-filter: blur(12px);
    position: absolute;
    width: 100%;
    z-index: 2;

    button {
      border: none;
      background: none;
      font-size: 2.5rem;
      margin-left: auto;
      color: ${({ theme }) => theme.colors.primary};
      padding: 2rem;

      svg {
        transition: ease 0.5s all;

        ${({ inTop }) =>
    !inTop &&
    css`
            transform: rotate(180deg);
          `};
      }

      ${mixins.md(css`
        display: none;
      `)}
    }

    ${mixins.md(css`
      font-size: 3.375rem;
      padding-left: 5rem;
    `)}
  }

  & > div {
    flex: 1;
    background: #f9f3f7;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .emptyState {
      max-width: 38rem;
      color: ${({ theme }) => theme.colors.primary};
      margin: auto;

      h1 {
        font-weight: bold;
        font-size: 5rem;
        line-height: 4.9rem;
        letter-spacing: -1px;
      }
    }
  }

  footer {
    margin-top: auto;
    display: flex;
    align-items: center;
    min-height: 13.6rem;
    justify-content: center;
    border-top: 2px solid rgba(112, 124, 151, 0.15);
    padding: 0 2rem;

    ${mixins.sm(css`
      padding: 0 5rem;
    `)}

    form {
      display: flex;
      width: 100%;

      & > div {
        display: flex;
        flex: 1;
        align-items: center;
        border-bottom: 2px solid rgba(112, 124, 151, 0.15);
        position: relative;

        & > input {
          display: flex;
          flex: 1;
          font-size: 2rem;
          border: none;
          padding-bottom: 1.25rem;
        }

        .emojiButton {
          margin-left: 1rem;
          border: none;
          background: none;
          cursor: pointer;
          display: none;

          ${mixins.md(css`
            display: flex;
          `)}
        }
      }
    }
    .sendButton,
    .emojiButton {
      min-width: 5rem;
      max-width: 5rem;
      height: 5rem;
      background: linear-gradient(325.78deg, #261283 14.76%, #8c7edb 87.3%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 50%;
      margin-left: 1rem;
      cursor: pointer;
      transition: ease all 0.2s;

      ${mixins.sm(css`
        margin-left: 4rem;
      `)}

      svg,
      path {
        fill: ${({ theme }) => theme.colors.white};
      }

      &:hover {
        box-shadow: 4px 4px 25px rgba(42, 139, 242, 0.15),
          2px 2px 25px rgba(42, 139, 242, 0.05),
          4px 6px 10px rgba(42, 139, 242, 0.15);
      }
    }
  }
`;

export const ContentPickerEmoji = styled.div`
  position: absolute;
  bottom: 8rem;
  right: 0;

  aside.emoji-picker-react .content-wrapper:before {
    display: none;
  }

  .emoji-picker-react .emoji-group:before {
    top: -1px;
  }
`;
