import styled, { css } from 'styled-components';

import { mixins } from '../../../styles/responsive';

export const Container = styled.section`
  margin-top: 5rem;

  ${mixins.md(css`
    margin-top: 10rem;
  `)}

  div > div {
    height: 3rem;
    width: 90%;

    ${mixins.md(css`
      width: 50%;
      height: 5rem;
    `)}
  }

  ul {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;

    li + li {
      margin-top: 4rem;
    }

    li > div {
      height: 5rem;

      ${mixins.md(css`
        height: 9.25rem;
      `)}
    }
  }
`;
