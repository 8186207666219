import React, { createContext, useCallback, useState } from 'react';

import useAuth from '../hooks/useAuth';

const MessageContext = createContext({ messages: [] });

const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const { user } = useAuth();

  const addMessage = useCallback(
    newMessage => {
      const newMessageSave = {
        ...newMessage,
        isMe: user.id === newMessage.user_id,
      };

      setMessages(state => [...state, newMessageSave]);
    },
    [user],
  );

  return (
    <MessageContext.Provider
      value={{
        addMessage,
        messages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export { MessageProvider, MessageContext };
