import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(request => {
  request.headers = { ...request.headers, apiKey: 'ApiKeyDeTeste' };
  return request;
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.pathname = '/';
    }
    throw error;
  },
);

export default api;
