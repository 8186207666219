const theme = {
  colors: {
    primary: '#594AAD',
    secundary: '#51cdae',
    secundary_hover: '#286657',
    white: '#FFFFFF',
    black: '#000000',
    error: '#F80027',
  },

  breakpoints: {
    xs: '480px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
    xlg: '1440px',
  },
};

export default theme;
