import React from 'react';
import { FiChevronLeft, FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import BackgroundImg from '../../assets/images/top-schedule.png';
import HaluLogo from '../../assets/images/halu-logo.png';
import { useAuth } from '../../hooks';

import { Container, Background } from './styles';

function Header({ goBack = false, date = '' }) {
  const history = useHistory();

  const { signOut } = useAuth();

  return (
    <Container>
      <Background>
        <img src={BackgroundImg} alt="" />
        <div />
      </Background>

      <main>
        <div>
          {goBack && (
            <button
              onClick={() => history.push(`/schedule/${date}`)}
              className="back"
              type="button"
            >
              <FiChevronLeft />
            </button>
          )}
          <button onClick={signOut} type="button">
            <FiLogOut />
          </button>
        </div>

        <img src={HaluLogo} alt="Halu Gamashi" />

        <h1>
          Ascendência
          <br /> com Halu
        </h1>
      </main>
    </Container>
  );
}

export default Header;
