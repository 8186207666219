import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;

  ${mixins.md(css`
    flex-direction: row;
    overflow-y: hidden;
  `)}

  > main {
    display: flex;
    flex-direction: column;
    background: #222;
    position: sticky;
    top: 0;
    z-index: 0;

    ${mixins.md(css`
      flex: 1;
      height: 100%;
    `)}

    header {
      position: absolute;
      top: 3rem;
      left: 3rem;

      ${mixins.md(css`
        top: 5rem;
        left: 5rem;
      `)}

      button {
        height: 6rem;
        width: 6rem;
        transition: ease all 0.2s;
        border-radius: 50%;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4rem;

        &:hover {
          transform: scale(1.3);
        }
      }
    }

    footer {
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 3rem 5rem;
      text-align: center;

      ${mixins.md(css`
        min-height: 26.5rem;
        max-height: 40rem;
        flex: 1;
      `)}

      img {
        display: none;

        ${mixins.md(css`
          display: block;
        `)}
      }

      img.invert {
        transform: scaleX(-1);
      }

      div {
        margin: 0 3rem;
      }

      h2 {
        font-weight: 600;
        font-size: 2.5rem;
        margin-bottom: 1rem;

        ${mixins.md(css`
          font-size: 4rem;
          margin-bottom: 2rem;
        `)}
      }

      p {
        font-size: 2rem;

        ${mixins.md(css`
          font-size: 3rem;
        `)}
      }
    }

    > div {
      width: 100%;
      background: #222;
      height: ${({ heightVideo }) => `${heightVideo}px`};
      margin: 0;

      ${mixins.md(css`
        height: ${({ heightVideo }) => `${heightVideo}px`};
        margin: auto;
      `)}

      div,
      iframe {
        height: 100%;
        width: 100%;
      }
    }
  }

  & > aside {
    width: 100%;
    min-width: 100%;
    margin-left: auto;
    position: relative;
    flex: 1;
    overflow: hidden;
    min-height: 90vh;
    box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.3);

    ${mixins.md(css`
      min-height: auto;
      flex: 0;
      width: 55.5rem;
      min-width: 55.5rem;
      box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0);
    `)}
  }
`;
