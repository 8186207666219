import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ItemSchedule from '../../components/ItemSchedule';
import Material from '../../components/Material';
import ScheduleShimmer from '../../components/Skeleton/ScheduleShimmer';
import DateHeaderShimmer from '../../components/Skeleton/DateHeaderShimmer';
import SVplayer from '../../components/SVplayer';
import liveStatus from '../../constants/live/status';
import { useWindowSize } from '../../hooks';
import api from '../../services/api';
import { getFormatedDate } from '../../utils/live';
import PlayerShimmer from '../../components/Skeleton/PlayerShimmer';
import MaterialShimmer from '../../components/Skeleton/MaterialShimmer';

import { Container, Content, Date, Player } from './styles';

function Class() {
  const [width] = useWindowSize();
  const playerDivRef = useRef();
  const containerRef = useRef();
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dateLive, setDateLive] = useState('');
  const [live, setLive] = useState({});
  const [materials, setMaterials] = useState([]);
  const [scheduleDay, setScheduleDay] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getLive = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(`live/${id}`);
        setLive(data);

        const { data: dataMaterial } = await api.get(
          `live/${id}/live-material`,
        );
        setMaterials(dataMaterial);

        const date = getFormatedDate(data.startAt, 'dd/MM/yyyy');
        setDateLive(getFormatedDate(data.startAt, 'yyyy-MM-dd'));

        const { data: dataResponse } = await api.get('/live/grouped-by-date', {
          params: { date },
        });

        setScheduleDay(dataResponse[date]);
        setLoading(false);
      } catch (e) {
        toast.error('Não foi possível carregar esse item.');
      }
    };

    if (id) {
      getLive();
    }
  }, [id]);

  useEffect(() => {
    if (playerDivRef.current) {
      const { offsetWidth } = playerDivRef.current;
      setHeight((offsetWidth * 9) / 16);
    }
  }, [width]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [id]);

  return (
    <Container ref={containerRef}>
      <Header goBack date={dateLive} />
      <Content ref={playerDivRef}>
        {!loading ? (
          <>
            <Date>
              <div>
                <h2>{live.startAt && getFormatedDate(live.startAt, 'eeee')}</h2>
                <p>{live.startAt && getFormatedDate(live.startAt, 'dd/MM')}</p>
              </div>
            </Date>

            <Player height={height}>
              <h3>{live.name}</h3>
              <div>
                {live.status === liveStatus.FINISHED && live.mediaId ? (
                  <SVplayer media={live.mediaId} ph={live.playerKey} />
                ) : (
                  <div className="coming">
                    <span>Disponível em breve</span>
                  </div>
                )}
              </div>
            </Player>
            {!!materials.length && <Material materials={materials} />}
          </>
        ) : (
          <>
            <DateHeaderShimmer />
            <PlayerShimmer height={height} />
            <MaterialShimmer />
          </>
        )}

        <hr />

        {!loading ? (
          <section>
            <h2>programação do dia</h2>
            <ul>
              {scheduleDay.length &&
                scheduleDay.map(schedule => (
                  <ItemSchedule
                    key={schedule.id}
                    status={
                      schedule.status === liveStatus.FINISHED &&
                      !schedule.mediaId
                        ? liveStatus.WAITING_VOD
                        : schedule.status
                    }
                    selected={id === schedule.id}
                    title={schedule.name}
                    description={schedule.description}
                    startAt={schedule.startAt}
                    endAt={schedule.endAt}
                    id={schedule.id}
                  />
                ))}
            </ul>
          </section>
        ) : (
          <ScheduleShimmer />
        )}
      </Content>
      <Footer />
    </Container>
  );
}

export default Class;
