import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.button)`
  padding: 1rem 2rem;
  border-radius: 6.25rem;
  border: 0;
  background: ${({ theme }) => theme.colors.secundary};
  color: #fff;

  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.secundary_hover};
  }
`;
