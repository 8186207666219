import React from 'react';

import Skeleton from '..';

import { Container } from './styles';

function DateHeaderShimmer() {
  return (
    <Container>
      <div>
        <Skeleton className="white" />
      </div>
      <div>
        <Skeleton className="white" />
      </div>
    </Container>
  );
}

export default DateHeaderShimmer;
