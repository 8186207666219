import React from 'react';
import { AnimatePresence } from 'framer-motion';

import { Container } from './styles';

const NewMessageButton = ({ isChatAutoScrollLock, handleUnLockChatScroll }) => {
  return (
    <AnimatePresence>
      {isChatAutoScrollLock && (
        <Container
          {...{
            initial: { opacity: 0 },
            animate: { opacity: 1 },
            exit: { opacity: 0 },
            transition: {
              duration: 0.2,
            },
          }}
          onClick={handleUnLockChatScroll}
        >
          Novas mensagens
        </Container>
      )}
    </AnimatePresence>
  );
};

export default NewMessageButton;
