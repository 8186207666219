import React, { useCallback, useEffect, useState } from 'react';
import { add, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { ChevronLeft, ChevronRight } from '../../assets/icons';
import ItemSchedule from '../../components/ItemSchedule';
import { getFormatedDate, getIsoDateFromShortDate } from '../../utils/live';
import ScheduleShimmer from '../../components/Skeleton/ScheduleShimmer';
import DateHeaderShimmer from '../../components/Skeleton/DateHeaderShimmer';
import liveStatus from '../../constants/live/status';
import useFetch from '../../hooks/useFetch';

import { Container, Content, Date as DateComponent } from './styles';

function Schedule() {
  const [dates, setDates] = useState([]);
  const [lives, setLives] = useState(undefined);
  const [day, setDay] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const { date } = useParams();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  const { data: livesData, error: livesError } = useFetch(
    '/live/grouped-by-date',
  );

  useEffect(() => {
    const getLives = async () => {
      try {
        const data = livesData;
        const livesGroup = Object.entries(data);

        livesGroup.forEach(item => {
          const shortData = item[1].sort((a, b) => {
            const dateA = new Date(a.startAt);
            const dateB = new Date(b.startAt);

            if (dateA > dateB) {
              return 1;
            }
            if (dateA < dateB) {
              return -1;
            }
            return 0;
          });

          data[item[0]] = shortData;
        });

        const datesLives = livesGroup.map(item => item[0]);

        const datesOrdened = datesLives.sort((a, b) => {
          let dateA = getIsoDateFromShortDate(a);
          let dateB = getIsoDateFromShortDate(b);

          dateA = new Date(dateA);
          dateB = new Date(dateB);

          if (dateA > dateB) {
            return 1;
          }
          if (dateA < dateB) {
            return -1;
          }
          return 0;
        });

        if (date && firstTime) {
          try {
            const indexDate = datesOrdened.indexOf(
              getFormatedDate(date, 'dd/MM/yyyy'),
            );
            setDay(indexDate < 0 ? 0 : indexDate);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Data inválida', e);
          }
        } else {
          let liveNow = false;

          livesGroup.forEach(item => {
            item[1].forEach(live => {
              if (live.status === liveStatus.IN_PROGRESS) {
                liveNow = live;
              }
            });
          });

          if (liveNow && firstTime) {
            const indexDate = datesOrdened.indexOf(
              getFormatedDate(liveNow.startAt, 'dd/MM/yyyy'),
            );
            setDay(indexDate < 0 ? 0 : indexDate);
          }
        }

        setFirstTime(false);
        setDates(datesOrdened);
        setLives(data);
      } catch (e) {
        toast.error(
          'Não foi possível carregar a programação. Recarregue a página.',
        );
      }
    };

    if (livesData) {
      getLives();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, livesData]);

  const handleNext = useCallback(() => {
    setDay(state => state + 1);
  }, []);

  const handlePrevious = useCallback(() => {
    setDay(state => state - 1);
  }, []);

  if (livesError) {
    toast.error(
      'Não foi possível carregar a programação. Recarregue a página.',
    );
  }

  return (
    <Container>
      <Header />
      {dates.length && lives ? (
        <Content>
          <DateComponent>
            <button disabled={day === 0} onClick={handlePrevious} type="button">
              <ChevronLeft />
            </button>

            <div>
              <h2>
                {format(
                  add(new Date(getIsoDateFromShortDate(dates[day])), {
                    hours: 3,
                  }),
                  'eeee',
                  { locale: pt },
                )}
              </h2>
              <p>
                {format(
                  add(new Date(getIsoDateFromShortDate(dates[day])), {
                    hours: 3,
                  }),
                  'dd/MM',
                  { locale: pt },
                )}
              </p>
            </div>

            <button
              disabled={day === dates.length - 1}
              onClick={handleNext}
              type="button"
            >
              <ChevronRight />
            </button>
          </DateComponent>

          <section>
            <h2>programação do dia</h2>
            <ul>
              {lives[dates[day]].map(live => (
                <ItemSchedule
                  status={
                    live.status === liveStatus.FINISHED && !live.mediaId
                      ? liveStatus.WAITING_VOD
                      : live.status
                  }
                  title={live.name}
                  description={live.description}
                  startAt={live.startAt}
                  endAt={live.endAt}
                  id={live.id}
                  key={live.id}
                />
              ))}
            </ul>
          </section>
        </Content>
      ) : (
        <Content>
          <DateHeaderShimmer />
          <ScheduleShimmer />
        </Content>
      )}
      <Footer />
    </Container>
  );
}

export default Schedule;
