/* eslint-disable no-new */
import AWS from 'aws-sdk';

import Config from '../config';

export const getAwsCredentials = () => {
  const credentials = new Promise((resolve, reject) => {
    const awsCredentials = JSON.parse(sessionStorage.getItem('awsCredentials'));
    if (awsCredentials) {
      return resolve(awsCredentials);
    }

    AWS.config.region = Config.awsRegion;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: Config.awsCognitoUserPoolId,
    });

    return AWS.config.credentials.get(error => {
      if (error) {
        reject(error);
      }

      const {
        accessKeyId,
        secretAccessKey,
        sessionToken,
      } = AWS.config.credentials;

      const credentialSubset = { accessKeyId, secretAccessKey, sessionToken };

      sessionStorage.setItem(
        'awsCredentials',
        JSON.stringify(credentialSubset),
      );

      resolve(credentialSubset);
    });
  });

  return credentials;
};

export const clearCachedId = () => {
  AWS.config.credentials.clearCachedId();
};
