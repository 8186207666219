import styled from 'styled-components';

export default styled.div`
  background-image: linear-gradient(-90deg, #e7edf1 0%, #fff 50%, #e7edf1 100%);
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;

  &.white {
    background-image: linear-gradient(-90deg, #fff 0%, #e7edf1 50%, #fff 100%);
  }

  &.dark {
    background-image: linear-gradient(-90deg, #222 0%, #333 50%, #222 100%);
  }

  &.dark-medium {
    background-image: linear-gradient(-90deg, #333 0%, #444 50%, #333 100%);
  }

  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;
