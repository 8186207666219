import React, { createContext, useState, useCallback, useEffect } from 'react';

// import { clearCachedId } from '../services/aws-cognito';
import api from '../services/api';

const AuthContext = createContext({ user: {} });

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Halu:token');
    const user = localStorage.getItem('@Halu:user');

    if (user && token) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
      };
    }

    return {};
  });

  const signIn = useCallback(async ({ password, email }) => {
    const { data: dataResponse } = await api.post('authentication/user/login', {
      password,
      email,
    });

    const { token, ...user } = dataResponse;

    localStorage.setItem('@Halu:token', token);
    localStorage.setItem('@Halu:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({
      token,
      user,
    });
  }, []);

  useEffect(() => {
    if (data.token) {
      api.get('/user/me');
    }
  }, [data.token]);

  const signOut = useCallback(() => {
    // clearCachedId();
    sessionStorage.clear();
    localStorage.clear();
    setData({});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut,
        setData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
