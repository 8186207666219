import React, { useCallback } from 'react';
import { FaPlay } from 'react-icons/fa';
import { format } from 'date-fns';
import {
  FiCheck,
  FiClock,
  FiEye,
  FiLock,
  FiRadio,
  FiUpload,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Parser from 'html-react-parser';

import liveStatus from '../../constants/live/status';

import { Container, Title, Description } from './styles';

function ItemSchedule({
  status = liveStatus.COMING,
  selected = false,
  title,
  description,
  startAt,
  endAt,
  id,
}) {
  const getIcon = useCallback(() => {
    switch (status) {
      case liveStatus.COMING:
        return <FiLock />;
      case liveStatus.IN_PROGRESS:
        return <FiRadio />;
      case liveStatus.FINISHED:
        return <FiCheck />;
      case liveStatus.WAITING_VOD:
        return <FiUpload />;
      default:
        return <FiLock />;
    }
  }, [status]);

  const history = useHistory();

  const handleClick = useCallback(() => {
    switch (status) {
      case liveStatus.COMING:
        return false;
      case liveStatus.IN_PROGRESS:
        history.push(`/live/${id}`);
        return true;
      default:
        history.push(`/class/${id}`);
        return true;
    }
  }, [history, id, status]);

  const getInfo = useCallback(() => {
    switch (status) {
      case liveStatus.COMING:
        return (
          <div>
            <FiClock />
            {startAt && format(new Date(startAt), "H'h'")}-{' '}
            {endAt && format(new Date(endAt), "H'h'")}
          </div>
        );
      case liveStatus.IN_PROGRESS:
        return (
          <div>
            <FiEye /> AO VIVO
          </div>
        );
      case liveStatus.FINISHED:
        return <div>{!selected && 'VER NOVAMENTE'}</div>;
      case liveStatus.WAITING_VOD:
        return <div>DISPONÍVEL EM BREVE</div>;
      default:
        return <div />;
    }
  }, [endAt, selected, startAt, status]);

  return (
    <Container role="button" tabIndex="0">
      <Title onClick={handleClick} selected={selected} status={status}>
        <div>
          <span> {selected ? <FaPlay /> : getIcon()}</span>
          <h3>{title}</h3>
        </div>
        {getInfo()}
      </Title>
      <Description selected={selected}>{Parser(description)}</Description>
    </Container>
  );
}

export default ItemSchedule;
