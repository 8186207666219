import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { ImSpinner8 } from 'react-icons/im';
import { FiInfo } from 'react-icons/fi';

import HaluLogo from '../../assets/images/halu-logo.png';
import Button from '../../components/Button';
import Input from '../../components/Input';
import { useAuth } from '../../hooks';

import { Container, Information } from './styles';

function SignIn() {
  const [user, setUser] = useState({ password: '', email: '' });
  const [isLoading, setLoading] = useState(false);

  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (isLoading) {
        return;
      }

      try {
        setLoading(true);
        await signIn(user);
      } catch (err) {
        toast.error(
          'Ocorreu um erro ao acessar a plataforma. Tente novamente.',
          {
            position: toast.POSITION.TOP_RIGHT,
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [isLoading, signIn, user],
  );

  return (
    <Container>
      <section>
        <img src={HaluLogo} alt="Halu Gamashi" />

        <main>
          <h1>
            Ascendência <br /> com Halu
          </h1>

          <div>
            <p>Retiro Virtual</p>
            <p>
              de <span>22</span> a <span>26</span> de Julho
            </p>
            <p>E 4 encontros extras para PERGUNTAS E RESPOSTAS</p>
          </div>
        </main>

        <form onSubmit={handleSubmit}>
          <Input
            label="SEU LOGIN"
            required
            onChange={email => {
              setUser({ ...user, email });
            }}
          />
          <Input
            label="SUA SENHA"
            required
            type="password"
            onChange={password => {
              setUser({ ...user, password });
            }}
          />
          <Button disabled={isLoading}>
            {isLoading ? <ImSpinner8 /> : 'Entrar'}
          </Button>

          <Information>
            <FiInfo />

            <div>
              Sua senha foi enviada no email que utilizou no cadastro. <br />
              Caso não tenha visto, procure também no <strong>SPAM</strong> ou
              na aba de <strong>PROMOÇÕES</strong>.
              <div>
                Segue nosso WhatsApp de Suporte{' '}
                <a
                  href="https://api.whatsapp.com/send?phone=+5511950578895&text=Ol%C3%A1,%20gostaria%20de%20tirar%20uma%20d%C3%BAvida."
                  target="_blank"
                  rel="noreferrer"
                >
                  +55(11)95057-8895.
                </a>
              </div>
            </div>
          </Information>
        </form>
      </section>

      <a
        href="https://halugamashi.com.br/ascendencia/"
        target="_blank"
        rel="noreferrer"
      >
        INFORMAÇÕES
      </a>
    </Container>
  );
}

export default SignIn;
