import styled, { css } from 'styled-components';

import { mixins } from '../../../styles/responsive';

export const Container = styled.section`
  div:nth-child(1) {
    > div {
      height: 4.5rem;
      width: 45rem;
      max-width: 90%;
      margin: auto;

      ${mixins.md(css`
        height: 6.5rem;
      `)}
    }
  }

  div:nth-child(2) {
    > div {
      height: 2.5rem;
      width: 7.5rem;
      max-width: 50%;
      margin: 1rem auto;

      ${mixins.md(css`
        height: 3rem;
      `)}
    }
  }
`;
