import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { cleanMessage } from '../utils/chat';
import * as IoT from '../services/aws-iot';
import { getAwsCredentials } from '../services/aws-cognito';

import useAuth from './useAuth';

export default function useChatSocket() {
  const { user } = useAuth();

  const [isBlock, setIsBlock] = useState(false);
  const [numberMessage, setNumberMessage] = useState(0);
  const [isInTime, setInTime] = useState(false);

  const sendMessageHook = useCallback(
    async (message, liveId) => {
      message = await cleanMessage(message);

      if (!message) {
        return;
      }

      if (isBlock) {
        toast.error('Você enviou muitas mensagens, aguarde alguns instantes.');
        return;
      }

      const now = new Date();
      const hour = now.getHours();
      const minutes = now.getMinutes();

      const topic = `halu-chat/${liveId}`;

      getAwsCredentials().then(() => {
        IoT.publish(topic, {
          message,
          room: liveId,
          name: user.name,
          time: `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`,
          userId: user.id,
          dateTime: new Date(),
        });
      });

      if (!isInTime) {
        setNumberMessage(0);
      }

      setNumberMessage(state => state + 1);

      setInTime(true);
      if (!isInTime) {
        setTimeout(() => {
          setInTime(false);
        }, 10000);
      }

      // Bloqeia usuário de enviar mensagens
      if (isInTime && numberMessage > 5) {
        setIsBlock(true);
        setTimeout(() => {
          setIsBlock(false);
          setNumberMessage(0);
        }, 30000);
      }
    },
    [isBlock, isInTime, numberMessage, user.name, user.id],
  );

  return { sendMessage: sendMessageHook };
}
