import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  min-height: 26.75rem;
  max-height: 26.75rem;
  background: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  position: relative;
  clip-path: polygon(0 100%, 0 20%, 50% 0, 100% 20%, 100% 100%);

  img {
    height: 4.125rem;
    margin-bottom: 2rem;
    margin-top: 3rem;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.white};
  }
`;
