import React, { useState } from 'react';
import { BiChevronUpCircle } from 'react-icons/bi';
import { FiCloud, FiDownload, FiFileText } from 'react-icons/fi';

import { Container, Title, Description } from './styles';

function Material({ materials }) {
  const ready = true;

  const [isOpen, setOpen] = useState(false);

  return (
    <Container>
      <Title
        open={!isOpen}
        disabled={!ready}
        onClick={() => setOpen(!isOpen)}
        ready={ready}
      >
        <div>
          <FiCloud />
          <h3>Material complementar</h3>
        </div>
        <div>{ready ? <BiChevronUpCircle /> : 'em breve'}</div>
      </Title>

      <Description open={isOpen}>
        <h4>ARQUIVO</h4>
        <ul>
          {materials.map(material => (
            <li>
              <FiFileText /> <p>{material.name}</p>
              <a
                download
                target="_blank"
                rel="noreferrer"
                href={material.sourceUrl}
              >
                <span> Download</span>
                <FiDownload />
              </a>
            </li>
          ))}
        </ul>
      </Description>
    </Container>
  );
}

export default Material;
