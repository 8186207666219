import React from 'react';
import { Switch } from 'react-router-dom';

import Class from '../pages/Class';
import Live from '../pages/Live';
import Schedule from '../pages/Schedule';
import SignIn from '../pages/SignIn';

import Route from './Route';

const Routes = () => {
  return (
    <Switch>
      <Route path="/live/:id" component={Live} isPrivate />
      <Route path="/schedule/:date" component={Schedule} isPrivate />
      <Route path="/schedule" component={Schedule} isPrivate />
      <Route path="/class/:id" component={Class} isPrivate />
      <Route path="/" component={SignIn} />
    </Switch>
  );
};

export default Routes;
