import React, { useCallback, useRef, useState } from 'react';
import Picker from 'emoji-picker-react';
import { FiArrowUp } from 'react-icons/fi';

import { Emoji, Send } from '../../assets/icons';
import { useChatSocket, useMessage } from '../../hooks';

import { Container, ContentPickerEmoji } from './styles';
import Messages from './Messages';

function Chat({ handleScroll, inTop, liveId }) {
  const inputMessage = useRef(null);
  const chatContainer = useRef(null);
  const [isOpenPicker, setIsOpenPicker] = useState(false);
  const { messages } = useMessage();
  const { sendMessage } = useChatSocket();
  const handleSendMessage = useCallback(
    e => {
      e.preventDefault();
      const message = inputMessage.current.value;
      sendMessage(message, liveId);
      inputMessage.current.value = '';
      inputMessage.current.focus();
    },
    [liveId, sendMessage],
  );

  const onEmojiClick = useCallback((event, emoji) => {
    inputMessage.current.value += emoji.emoji;
  }, []);

  return (
    <Container inTop={inTop}>
      <header>
        Chat
        <button onClick={() => handleScroll()} type="button">
          <FiArrowUp />
        </button>
      </header>

      <div ref={chatContainer}>
        {!messages.length ? (
          <div className="emptyState">
            <h1>Interaja com ou outros participantes pelo Chat.</h1>
          </div>
        ) : (
          <Messages />
        )}
      </div>

      <footer>
        <form onSubmit={handleSendMessage}>
          <div>
            <input
              onFocus={() => {
                setIsOpenPicker(false);
              }}
              ref={inputMessage}
              type="text"
              placeholder="Digite a sua mensagem aqui"
            />
            <button
              onClick={() => setIsOpenPicker(!isOpenPicker)}
              className="emojiButton"
              type="button"
            >
              <Emoji />
            </button>

            {isOpenPicker && (
              <ContentPickerEmoji>
                <Picker onEmojiClick={onEmojiClick} />
              </ContentPickerEmoji>
            )}
          </div>

          <button className="sendButton" type="submit">
            <Send />
          </button>
        </form>
      </footer>
    </Container>
  );
}

export default Chat;
