import { createGlobalStyle, css } from 'styled-components';

import { mixins } from './responsive';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', 'Segoe UI', -apple-system,  'Segoe UI', Ubuntu, 'Open Sans', 'Helvetica Neue', sans-serif ;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    font-size: 8px;

    ${mixins.md(css`
      font-size: 7px;
    `)}

    ${mixins.xlg(css`
      font-size: 8px;
    `)}
  }

  #root {
    display: flex;
  }

  body, button, input, a {
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  .Toastify__toast{
    font-size: 2rem;
  }

`;
