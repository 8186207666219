import styled, { css } from 'styled-components';

import { mixins } from '../../../styles/responsive';

export const Container = styled.section`
  div:nth-child(1) {
    > div {
      margin-top: 6rem;
      margin-bottom: 2rem;
      width: 90%;
      height: 3rem;

      ${mixins.md(css`
        margin-bottom: 3rem;
        width: 50%;
        height: 4rem;
      `)};
    }
  }

  div:nth-child(2) {
    > div {
      height: ${({ height }) => `${height}px`};
      width: 100%;
    }
  }
`;
