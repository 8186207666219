import { format, add } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

export const getIsoDateFromShortDate = date => {
  const dateArray = date.split('/');
  return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
};

export const getFormatedDate = (date, formatStr, addHours = true) => {
  return format(
    add(new Date(date), {
      hours: addHours ? 3 : 0,
    }),
    formatStr,
    { locale: pt },
  );
};
