import Filter from 'bad-words';

const badWordsList = require('../resources/bad_words/pt_BR.json');

export const cleanMessage = async message => {
  if (!message) {
    return false;
  }

  message.trim();

  if (!message) {
    return false;
  }

  const filter = new Filter({ list: badWordsList.words });
  const isProfane = await filter.isProfane(message);

  if (isProfane) {
    return false;
  }

  return message;
};
