import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { AiOutlineUser } from 'react-icons/ai';

import { useMessage } from '../../../hooks';
import NewMessageButton from '../NewMessageButton';

import { Wrapper, Container, Message } from './styles';

const Messages = () => {
  const { messages } = useMessage();

  const wrapperRef = useRef(null);
  const scrollingRef = useRef();

  const [isChatAutoScrollLock, setIsChatAutoScrollLock] = useState(false);
  const [showGoToEnd, setShowGoToEnd] = useState(false);

  const scrollToFn = React.useCallback((offset, defaultScrollTo) => {
    const wrapperEle = document.getElementById('wrapper');
    const start = wrapperEle.scrollTop;

    // eslint-disable-next-line no-multi-assign
    const startTime = (scrollingRef.current = Date.now());

    const run = () => {
      if (scrollingRef.current === startTime) {
        const now = Date.now();
        const elapsed = now - startTime;
        const interpolated = start + (offset - start) * elapsed;

        defaultScrollTo(interpolated);
      }
    };

    requestAnimationFrame(run);
  }, []);

  const rowVirtualizer = useVirtual({
    size: messages.length,
    parentRef: wrapperRef,
    scrollToFn,
  });

  useEffect(() => {
    if (!isChatAutoScrollLock) {
      rowVirtualizer.scrollToIndex(messages.length);
    }
  }, [isChatAutoScrollLock, rowVirtualizer, messages]);

  useEffect(() => {
    if (isChatAutoScrollLock) {
      setShowGoToEnd(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleScroll = useCallback(() => {
    const isChatScolled =
      wrapperRef.current.scrollHeight -
        wrapperRef.current.scrollTop -
        wrapperRef.current.clientHeight >
      10;

    setIsChatAutoScrollLock(isChatScolled);
  }, []);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: 'easeOut', duration: 0.5 }}
      id="wrapper"
      ref={wrapperRef}
      onScroll={handleScroll}
    >
      <Container style={{ height: `${rowVirtualizer.totalSize}px` }}>
        {rowVirtualizer.virtualItems.map(virtualItem => {
          const message = messages[virtualItem.index];

          return (
            <Message
              isMe={message.isMe}
              key={virtualItem.index}
              ref={virtualItem.measureRef}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${messages[virtualItem.index]}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <div>
                <div>
                  <AiOutlineUser />
                </div>
                <div>
                  <h1>
                    {message.name.split(' ')[0]} <span>{message.time}</span>
                  </h1>
                  <div>
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-3.27835e-07 6.5L11.25 0.00480889L11.25 12.9952L-3.27835e-07 6.5Z"
                        fill="white"
                      />
                    </svg>
                    <p>{message.message}</p>
                  </div>
                </div>
              </div>
            </Message>
          );
        })}
      </Container>

      <NewMessageButton
        isChatAutoScrollLock={showGoToEnd}
        handleUnLockChatScroll={() => {
          setIsChatAutoScrollLock(false);
          setShowGoToEnd(false);
          rowVirtualizer.scrollToIndex(messages.length);
        }}
      />
    </Wrapper>
  );
};

export default Messages;
