import styled, { css } from 'styled-components';

import Thumb from '../../assets/images/thumbnail.png';
import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Player = styled.div`
  margin-top: 6rem;

  h3 {
    margin-bottom: 2rem;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;

    ${mixins.md(css`
      font-size: 3rem;
      margin-bottom: 3rem;
    `)}
  }

  > div {
    height: ${({ height }) => `${height}px`};
    background: #222;

    .coming {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Montserrat;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 3rem;
      color: #ffffff;
      background: url(${Thumb}) center/cover;
      text-align: center;

      ${mixins.md(css`
        font-size: 5.375rem;
      `)}
    }

    iframe,
    div {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 140rem;
  margin: auto;
  padding: 4rem 3rem;

  ${mixins.md(css`
    padding: 7rem 3rem;
  `)}

  hr {
    border: 2px dotted #202a88;
    margin-top: 6rem;
    margin-bottom: 3rem;
    border-top-width: 5px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;

    ${mixins.md(css`
      margin-top: 9rem;
      margin-bottom: 6rem;
    `)}
  }

  section {
    margin-top: 5rem;

    ${mixins.md(css`
      margin-top: 10rem;
    `)}

    h2 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 3rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primary};
      text-align: center;

      ${mixins.md(css`
        font-size: 4rem;
        text-align: left;
      `)}
    }

    ul {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: auto 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 3.5rem;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primary};

      ${mixins.md(css`
        font-size: 6rem;
      `)}
    }

    p {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 3rem;
      text-align: center;
      color: #666666;
    }
  }
`;
