import useSWR from 'swr';

import api from '../services/api';

export default function useFetch(url, queryParams, options = {}) {
  const { data, error, mutate } = useSWR(
    url,
    async urlData => {
      const response = await api.get(urlData, queryParams);

      return response.data;
    },
    {
      revalidateOnReconnect: true,
      ...options,
    },
  );

  return { data, error, mutate };
}
