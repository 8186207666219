import React from 'react';

import Skeleton from '..';

import { Container } from './styles';

function PlayerShimmer({ height }) {
  return (
    <Container height={height}>
      <div>
        <Skeleton className="white" />
      </div>
      <div>
        <Skeleton className="white" />
      </div>
    </Container>
  );
}

export default PlayerShimmer;
