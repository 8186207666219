import React from 'react';

import Skeleton from '..';

import { Container } from './styles';

function ScheduleShimmer() {
  return (
    <Container>
      <div>
        <Skeleton className="white" />
      </div>

      <ul>
        <li>
          <Skeleton className="white" />
        </li>

        <li>
          <Skeleton className="white" />
        </li>

        <li>
          <Skeleton className="white" />
        </li>
      </ul>
    </Container>
  );
}

export default ScheduleShimmer;
