import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useMessage, useWindowSize } from '../../hooks';
import * as IoT from '../../services/aws-iot';
import { getAwsCredentials } from '../../services/aws-cognito';
import Chat from '../../components/Chat';
import SVplayer from '../../components/SVplayer';
import IconHalu from '../../assets/images/lion.png';
import api from '../../services/api';
import LiveShimmer from '../../components/Skeleton/LiveShimmer';
import { getFormatedDate } from '../../utils/live';
import liveStatus from '../../constants/live/status';

import { Container } from './styles';

function Live() {
  const { addMessage } = useMessage();
  const [width] = useWindowSize();
  const playerDivRef = useRef();
  const containerRef = useRef();
  const [height, setHeight] = useState(0);
  const [dateLive, setDateLive] = useState('');
  const history = useHistory();
  const [inTop, setInTop] = useState(false);
  const [live, setLive] = useState({});
  const { id } = useParams();
  const [, heightWindow] = useWindowSize();

  useEffect(() => {
    const getLive = async () => {
      try {
        const { data } = await api.get(`live/${id}`);
        setDateLive(getFormatedDate(data.startAt, 'yyyy-MM-dd'));

        if (data.status === liveStatus.COMING) {
          history.push('/schedule');
          return;
        }

        if (data.status !== liveStatus.IN_PROGRESS) {
          history.push(`/class/${id}`);
          return;
        }
        setLive(data);
      } catch (e) {
        toast.error('Não foi possível carregar a live.');
      }
    };

    if (id) {
      getLive();
    }
  }, [history, id]);

  useEffect(() => {
    if (playerDivRef.current) {
      const { offsetWidth } = playerDivRef.current;
      setHeight((offsetWidth * 9) / 16);
    }
  }, [width, live.id]);

  const handleScroll = useCallback(() => {
    if (!inTop) {
      containerRef.current.scrollTop = 0;
    } else {
      containerRef.current.scrollTop = heightWindow;
    }

    setInTop(!inTop);
  }, [heightWindow, inTop]);

  const handleScrollPage = useCallback(e => {
    if (e.target.scrollTop > 10) {
      setInTop(false);
    } else {
      setInTop(true);
    }
  }, []);

  useEffect(() => {
    if (live.id) {
      sessionStorage.clear();
      getAwsCredentials().then(() => {
        const topic = `halu-chat/${live.id}`;
        IoT.initNewClient();
        IoT.subscribe(topic);
        IoT.attachMessageHandler((topicAttached, jsonPayload) => {
          const payload = JSON.parse(jsonPayload.toString());
          addMessage(payload.message);
        });
      });
    }

    return () => {
      if (live.id) {
        const topic = `halu-chat/${live.id}`;
        IoT.unsubscribeFromTopics([topic]);
        IoT.disconnect();
        sessionStorage.clear();
      }
    };
  }, [addMessage, live.id]);

  if (!live.id) {
    return (
      <Container>
        <main>
          <header>
            <button
              onClick={() => history.push(`/schedule/${dateLive}`)}
              type="button"
            >
              <FiChevronLeft />
            </button>
          </header>
        </main>
        <LiveShimmer />
      </Container>
    );
  }

  return (
    <Container
      onScroll={handleScrollPage}
      ref={containerRef}
      heightVideo={height}
    >
      <main>
        <header>
          <button
            onClick={() => history.push(`/schedule/${dateLive}`)}
            type="button"
          >
            <FiChevronLeft />
          </button>
        </header>
        <div ref={playerDivRef}>
          <SVplayer live={live.liveId} ph={live.playerKey} />
        </div>
        <footer>
          <img src={IconHalu} alt="Icone Halu Gamashi" />
          <div>
            <h2>{live.name}</h2>
            <p>{live.description}</p>
          </div>
          <img className="invert" src={IconHalu} alt="Icone Halu Gamashi" />
        </footer>
      </main>
      <aside>
        <Chat liveId={live.id} inTop={inTop} handleScroll={handleScroll} />
      </aside>
    </Container>
  );
}

export default Live;
