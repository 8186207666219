import styled, { css } from 'styled-components';

import liveStatus from '../../constants/live/status';
import { mixins } from '../../styles/responsive';

export const Container = styled.li`
  + li {
    margin-top: 4rem;
  }
`;

export const Title = styled.div`
  background: #eaebf7;
  border-radius: 2rem;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: ease all 0.2s;

  ${mixins.md(css`
    padding: 2.5rem;
  `)}

  &:hover {
    transform: scale(1.02);
  }

  ${({ status }) =>
    status === liveStatus.IN_PROGRESS &&
    css`
      background: #ffc703;
    `}

  ${({ status }) =>
    (status === liveStatus.WAITING_VOD || status === liveStatus.FINISHED) &&
    css`
      background: #c9ebd0;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: #c9ebd0;
      border: 2px solid #28b043;
    `}

  div:nth-child(1) {
    margin-right: 3rem;
    display: flex;
    align-items: center;

    h3 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 1.75rem;
      text-transform: uppercase;

      ${mixins.md(css`
        font-size: 3rem;
      `)}
    }

    span {
      min-height: 3.5rem;
      min-width: 3.5rem;
      background: ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.white};
      border-radius: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      margin-right: 1rem;

      ${mixins.md(css`
        margin-right: 2.5rem;
        min-height: 5rem;
        min-width: 5rem;
        font-size: 3rem;
      `)}

      ${({ status }) =>
    status === liveStatus.IN_PROGRESS &&
    css`
          background: none;
          font-size: 3rem;
          color: #000;

          ${mixins.md(css`
            font-size: 4rem;
          `)}
        `};

      ${({ status }) =>
    (status === liveStatus.WAITING_VOD || status === liveStatus.FINISHED) &&
    css`
          background: #28b043;
        `};

      ${({ selected }) =>
    selected &&
    css`
          background: #28b043;
          border-radius: 50%;
          font-size: 2rem;
          opacity: 0.5;

          ${mixins.md(css`
            font-size: 2.5rem;
          `)}

          svg {
            margin-right: -0.5rem;
            color: #fff;
          }
        `};
    }
  }

  div:nth-child(2) {
    color: #000;
    font-family: Montserrat;
    font-size: 1.75rem;
    align-items: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-align: center;

    ${mixins.md(css`
      font-size: 3rem;
      text-align: left;
      font-weight: bold;
    `)}

    ${({ status }) =>
    status === liveStatus.COMING &&
    css`
        white-space: nowrap;
        color: ${({ theme }) => theme.colors.primary};
      `};

    ${({ status }) =>
    status === liveStatus.WAITING_VOD &&
    css`
        color: #666666;
      `};

    ${({ status }) =>
    status === liveStatus.FINISHED &&
    css`
        color: #28b043;
      `};

    ${({ selected }) =>
    selected &&
    css`
        color: #666666;
      `};

    svg {
      margin-right: 0.5rem;

      ${mixins.md(css`
        margin-right: 1.5rem;
      `)}
    }
  }
`;

export const Description = styled.div`
  background: rgba(79, 90, 189, 0.04);
  border-radius: 0px 0px 16px 16px;
  padding: 3rem 2rem 2rem;
  margin-top: -1.5rem;
  position: relative;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 1px;

  ${mixins.md(css`
    font-size: 2rem;
    padding: 4rem 2.5rem 2.5rem;
  `)}

  ${({ selected }) =>
    selected &&
    css`
      background: rgba(201, 235, 208, 0.32);
    `};
`;
