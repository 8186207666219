import styled, { css, keyframes } from 'styled-components';

import Background from '../../assets/images/halu-background.png';
import { Container as Button } from '../../components/Button/styles';
import { mixins } from '../../styles/responsive';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background: url(${Background}) center/cover;
  height: 100%;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding: 3rem;

  ${mixins.sm(css`
    flex-direction: row;
  `)}

  ${mixins.md(css`
    flex-direction: row;
    padding: 0;
  `)}

  > a {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 7.75rem;
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 3rem;
    margin-bottom: auto;
    border: 2px solid transparent;
    transition: ease all 0.2s;
    text-align: center;
    width: 27rem;
    margin-left: auto;

    &:hover {
      transform: scale(1.05);
    }

    ${mixins.md(css`
      margin-top: 5rem;
      margin-right: 5rem;
      margin-bottom: auto;
      width: auto;
    `)}
  }

  section {
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    ${mixins.md(css`
      padding: 3rem 15rem 3rem;
      margin-top: 0rem;
    `)}

    img {
      width: 54rem;
      max-width: 100%;
      margin-bottom: 5rem;
      margin-top: auto;

      ${mixins.md(css`
        margin-bottom: 5rem;
      `)}
    }

    main {
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;

      h1 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 800;
        font-size: 4.5rem;
        line-height: 119.9%;
        text-transform: uppercase;
        margin-bottom: 4rem;
        text-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12),
          0px 4px 4px rgba(24, 39, 75, 0.08);

        ${mixins.md(css`
          font-size: 6rem;
          margin-bottom: 5rem;
        `)}
      }

      div {
        margin-bottom: 6rem;

        p {
          font-family: Montserrat;
        }

        p:nth-child(1) {
          font-weight: normal;
          font-size: 3rem;
        }

        p:nth-child(2) {
          font-weight: 600;
          font-size: 5rem;
          text-transform: uppercase;

          span {
            font-weight: 900;
          }
        }

        p:nth-child(3) {
          font-weight: normal;
          font-size: 2rem;
        }
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 61.5rem;
      max-width: 100%;
      margin-bottom: auto;
      padding-bottom: 3rem;

      ${Button} {
        margin-top: 3rem;
        margin-left: 0;

        ${mixins.md(css`
          margin-top: 5rem;
          margin-left: auto;
        `)}

        svg {
          color: ${({ theme }) => theme.colors.white};
          animation: ${rotate} 1s linear infinite;
        }
      }
    }
  }
`;

export const Information = styled.div`
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  background: rgba(5, 4, 15, 70%);
  border-radius: 8px;
  padding: 16px;
  position: relative;

  a {
    color: #fff;
    text-decoration: underline;
  }

  svg {
    margin-right: 8px;
    font-size: 32px;
    position: absolute;
    top: -16px;
    left: -16px;
  }
`;
