import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  min-height: 63.125rem;
  max-height: 63.125rem;
  width: 100%;
  position: relative;

  ${mixins.md(css`
    min-height: 83.125rem;
    max-height: 83.125rem;
  `)}

  main {
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    color: ${({ theme }) => theme.colors.white};
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    ${mixins.md(css`
      padding: 3rem 0 3rem 12rem;
      align-items: flex-start;
    `)}

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0
      margin-left: auto;
      width: 100%;

      ${mixins.md(css`
        padding: 2rem 6rem 3rem 0;
      `)}

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background: none;
        font-size: 4rem;
        color: ${({ theme }) => theme.colors.white};
        height: 6rem;
        width: 6rem;
        transition: ease all 0.2s;
        margin-left: auto;

        &:hover {
          transform: scale(1.3);
        }

        &.back {
          background: ${({ theme }) => theme.colors.primary};
          border-radius: 50%;
          margin-left: 0;
        }
      }
    }

    img {
      width: 40.875rem;
      max-width: 100%;
      height: auto;
      margin-bottom: 2rem;
      margin-top: auto;

      ${mixins.md(css`
        width: 56.875rem;
      `)}
    }

    h1 {
      margin-bottom: auto;
      font-family: Montserrat;
      font-weight: 800;
      font-size: 5rem;
      line-height: 119.9%;
      text-transform: uppercase;
      text-shadow: 0px 2px 4px rgba(24, 39, 75, 0.12),
        0px 4px 4px rgba(24, 39, 75, 0.08);
      text-align: center;

      ${mixins.md(css`
        font-size: 7rem;
        text-align: left;
      `)}
    }
  }
`;

export const Background = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  > div {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background: linear-gradient(
      90deg,
      rgba(89, 74, 173, 0.83) -5.53%,
      rgba(159, 151, 201, 0.3818) 60.04%,
      rgba(255, 255, 255, 0) 131.32%
    );
    opacity: 0.81;
  }

  clip-path: polygon(0 80%, 0 0, 100% 0, 100% 80%, 50% 100%);
`;
