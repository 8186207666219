import React, { useCallback, useEffect, useRef } from 'react';

function SVplayer({ ph, live = null, media = null }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const initPlayer = useCallback(() => {
    playerRef.current = null;
    videoRef.current.innerHTML = '';

    playerRef.current = new window.SambaPlayer('playerId', {
      ph,
      live: media ? null : live,
      m: media || null,
      playerParams: {
        enableShare: false,
      },
    });
  }, [live, media, ph]);

  useEffect(() => {
    initPlayer();
  }, [initPlayer]);

  return <div ref={videoRef} id="playerId" />;
}

export default SVplayer;
