import React from 'react';

import Skeleton from '..';

import { Container } from './styles';

function MaterialShimmer() {
  return (
    <Container>
      <Skeleton className="white" />
    </Container>
  );
}

export default MaterialShimmer;
