import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.white};

  > div {
    background: ${({ theme }) => theme.colors.white};
    border: 3px solid ${({ theme }) => theme.colors.primary};
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    overflow: hidden;

    > button {
      height: 100%;
      padding: 0 2rem;
      font-size: 3rem;
      display: flex;
      align-items: center;
      color: rgba(69, 69, 69, 0.49);
      background: none;
      border: none;
    }
  }

  input {
    border: none;
    flex: 1;
    padding: 2rem;
    color: rgba(69, 69, 69, 0.49);
    font-weight: bold;
    font-size: 2rem;
  }

  & + div {
    margin-top: 3rem;

    ${mixins.md(css`
      margin-top: 4.5rem;
    `)}
  }
`;
