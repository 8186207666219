import React from 'react';

import HaluLogo from '../../assets/images/halu-logo.png';

import { Container } from './styles';

function Footer() {
  return (
    <Container>
      <img src={HaluLogo} alt="Halu Gamashi" />
      <p>© Copyright 2021 - Todos direitos reservados.</p>
    </Container>
  );
}

export default Footer;
