import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { mixins } from '../../../styles/responsive';

export const Wrapper = styled(motion.div)`
  overflow-x: auto;
  min-height: 100%;
  padding: calc(77px + 16px) 3rem 3rem;

  ${mixins.md(css`
    padding: calc(77px + 16px) 5rem 5rem;
  `)}

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background: #0068b0;
    outline: 0px;
    border-radius: 16px;
  }
`;

export const Container = styled.ul`
  position: relative;
  width: 100%;
  display: block;
`;

export const Message = styled.li`
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2rem;
  }

  > div {
    display: flex;

    ${({ isMe }) =>
    isMe &&
    css`
        flex-direction: row-reverse;
        margin-left: auto;
      `}

    > div:nth-child(1) {
      height: 6.25rem;
      min-width: 6.25rem;
      max-width: 6.25rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #d8d3f4;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 3rem;
      margin-right: 1rem;

      ${({ isMe }) =>
    isMe &&
    css`
          margin-right: 0;
          margin-left: 1rem;
        `}
    }

    > div:nth-child(2) {
      display: inline-flex;
      flex-direction: column;
      transition: ease all 0.2s;
      margin-bottom: 2rem;

      h1 {
        display: flex;
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 1rem;
        padding-left: 1rem;
        color: #1d116a;

        ${({ isMe }) =>
    isMe &&
    css`
            flex-direction: row-reverse;
            padding-left: 0px;
            padding-right: 1rem;
          `}

        span {
          margin: auto 0 auto auto;
          color: #676767;
          font-size: 1.5rem;
          padding-left: 2rem;

          ${({ isMe }) =>
    isMe &&
    css`
              padding-left: 0rem;
              padding-right: 2rem;
            `}
        }
      }

      > div {
        position: relative;

        svg {
          position: absolute;
          z-index: 0;
          top: 4px;

          ${({ isMe }) =>
    isMe &&
    css`
              right: -8px;
              transform: rotate(180deg);

              &,
              path {
                fill: #00497d;
              }
            `}
        }
      }

      p {
        width: 100%;
        padding: 8px;
        border-radius: 0.5rem;
        background: #ffffff;
        box-shadow: 0px 1px 1px rgba(17, 17, 17, 0.24);
        border-radius: 4px;
        font-family: Montserrat;
        font-size: 14px;
        line-height: 20px;
        z-index: 1;
        margin-left: 8px;

        ${({ isMe }) =>
    isMe &&
    css`
            background: #00497d;
            color: #fff;
            margin-left: 0px;
          `}
      }
    }
  }
`;
