import React from 'react';

import { AuthProvider } from './auth';
import { MessageProvider } from './message';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <MessageProvider>{children}</MessageProvider>
  </AuthProvider>
);

export default AppProvider;
