import { useContext } from 'react';

import { MessageContext } from '../context/message';

export default function useMessage() {
  const context = useContext(MessageContext);

  if (!context) {
    throw new Error('useMessage must be used within an MessageContext');
  }

  return context;
}
