import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  margin-top: 8rem;
`;

export const Title = styled.button`
  border-radius: 2rem;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  cursor: pointer;
  transition: ease all 0.2s;
  border: 1px solid #a5a7c7;
  color: #a5a7c7;
  background: transparent;

  ${mixins.md(css`
    padding: 2.5rem;
  `)}

  &:hover {
    transform: scale(1.02);
  }

  ${({ ready }) =>
    ready &&
    css`
      background: #eaebf7;
      border: 1px solid transparent;
      color: #202a88;
    `}

  div:nth-child(1) {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    font-size: 3rem;

    h3 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 2rem;
      text-transform: uppercase;
      text-align: left;

      ${mixins.md(css`
        font-size: 3rem;
      `)}
    }

    svg {
      font-size: 4rem;
      margin-right: 1rem;

      ${mixins.md(css`
        margin-right: 2rem;
      `)}
    }
  }

  div:nth-child(2) {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 3rem;
    align-items: center;
    display: flex;
    align-items: center;
    color: #a5a7c7;
    text-transform: uppercase;

    svg {
      font-size: 3rem;
      color: #202a88;
      transition: all ease 0.2s;

      ${mixins.md(css`
        font-size: 4rem;
      `)}

      ${({ open }) =>
    open &&
    css`
          transform: rotate(180deg);
        `};
    }
  }
`;

export const Description = styled.div`
  background: rgba(79, 90, 189, 0.04);
  border-radius: 0px 0px 16px 16px;
  padding: 0rem 2.5rem 0rem;
  margin-top: -1.5rem;
  position: relative;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 1.5px;
  max-height: 0px;
  overflow: hidden;
  transition: ease all 0.2s;

  ${({ open }) =>
    open &&
    css`
      max-height: none;
      padding: 3rem 1.5rem 1.5rem;

      ${mixins.md(css`
        padding: 4rem 2.5rem 2.5rem;
      `)}
    `};

  h4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-transform: uppercase;

    ${mixins.md(css`
      margin-bottom: 2rem;
    `)}
  }

  ul {
    margin-bottom: 1rem;

    li {
      color: #202a88;
      font-size: 2rem;
      display: flex;
      align-items: center;

      ${mixins.md(css`
        font-size: 3rem;
      `)}

      & + li {
        margin-top: 3rem;
      }

      p {
        font-weight: normal;
        flex: 1;
      }

      a {
        margin-left: auto;
        font-weight: 600;
        font-size: 2rem;
        text-transform: uppercase;
        color: #ffffff;
        padding: 0.5rem 1rem;
        background: #202a88;
        border-radius: 1rem;
        border: none;
        display: flex;

        span {
          display: none;

          ${mixins.md(css`
            display: flex;
            margin-right: 1rem;
          `)}
        }
      }

      > svg {
        margin-right: 1rem;

        ${mixins.md(css`
          margin-right: 3rem;
        `)}
      }
    }
  }
`;
