import styled, { css } from 'styled-components';

import { mixins } from '../../styles/responsive';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
  width: 100%;
  max-width: 140rem;
  margin: auto;
  padding: 4rem 3rem;

  ${mixins.md(css`
    padding: 7rem 3rem;
  `)}

  section {
    margin-top: 5rem;

    ${mixins.md(css`
      margin-top: 10rem;
    `)}

    h2 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 3rem;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primary};
      text-align: center;

      ${mixins.md(css`
        font-size: 4rem;
        text-align: left;
      `)}
    }

    ul {
      margin-top: 4rem;
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: none;
    background: none;
    transition: ease 0.2s all;

    svg,
    path {
      stroke: ${({ theme }) => theme.colors.primary};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      svg,
      path {
        stroke: #ddd;
      }
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  div {
    margin: auto 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 3.5rem;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primary};

      ${mixins.md(css`
        font-size: 6rem;
      `)}
    }

    p {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 3rem;
      text-align: center;
      color: #666666;
    }
  }
`;
